import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Navigation from "../components/navigation";
import "../styles/prismjs/custom-blue-monokai.css";

export default ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );

  return (
    <div className="site-wrapper">
      <header className="site-header">
        <div className="site-title">
          <Link to="/">
            {data.site.siteMetadata.title}{" "}
            <span className="surname">Shrestha</span>
          </Link>
        </div>
        <Navigation />
      </header>
      {children}
      <footer className="site-footer">
        <p>&copy; Bipin</p>
        <div className="social-links">
          <a
            href="https://github.com/bipinstha7"
            target="_blank"
            className="github"
            rel="noopener noreferrer"
          >
            <svg
              width="30"
              height="30"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.49936 0.850006C3.82767 0.850006 0.849976 3.8273 0.849976 7.50023C0.849976 10.4379 2.75523 12.9306 5.39775 13.8104C5.73047 13.8712 5.85171 13.6658 5.85171 13.4895C5.85171 13.3315 5.846 12.9135 5.84273 12.3587C3.99301 12.7604 3.60273 11.4671 3.60273 11.4671C3.30022 10.6988 2.86423 10.4942 2.86423 10.4942C2.26044 10.0819 2.90995 10.0901 2.90995 10.0901C3.57742 10.137 3.9285 10.7755 3.9285 10.7755C4.52167 11.7916 5.48512 11.4981 5.86396 11.3279C5.92438 10.8984 6.09625 10.6053 6.28608 10.4391C4.80948 10.2709 3.25695 9.70063 3.25695 7.15241C3.25695 6.42615 3.51618 5.83298 3.94157 5.368C3.87299 5.1998 3.64478 4.52375 4.00689 3.60807C4.00689 3.60807 4.56494 3.42926 5.83538 4.28941C6.36568 4.14204 6.93477 4.06856 7.50018 4.0657C8.06518 4.06856 8.63386 4.14204 9.16498 4.28941C10.4346 3.42926 10.9918 3.60807 10.9918 3.60807C11.3548 4.52375 11.1266 5.1998 11.0584 5.368C11.4846 5.83298 11.7418 6.42615 11.7418 7.15241C11.7418 9.70716 10.1868 10.2693 8.70571 10.4338C8.94412 10.6392 9.15681 11.045 9.15681 11.6655C9.15681 12.5542 9.14865 13.2715 9.14865 13.4895C9.14865 13.6675 9.26867 13.8745 9.60588 13.8095C12.2464 12.9282 14.15 10.4375 14.15 7.50023C14.15 3.8273 11.1723 0.850006 7.49936 0.850006Z"
                fill="currentColor"
                fillRule="evenodd"
                clipRule="evenodd"
              ></path>
            </svg>
          </a>
          <a
            href="https://twitter.com/bipinstha7"
            target="_blank"
            className="twitter"
            rel="noopener noreferrer"
          >
            <svg
              width="30"
              height="30"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.38663 2.54901C3.68244 4.13804 5.61715 5.18406 7.80109 5.29402C7.7571 5.10117 7.73342 4.90155 7.73342 4.69629C7.73342 3.24485 8.90969 2.06857 10.36 2.06857C11.9487 2.06857 12.9956 3.52127 12.9956 5.00079C12.9956 8.47209 10.3538 12.4746 5.52128 12.4746C4.03826 12.4746 2.65786 12.0404 1.49512 11.2944"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M2.29578 2.51804C2.04278 2.92585 2.03122 3.38915 2.03122 3.86958C2.03122 4.78082 2.4953 5.58549 3.1996 6.05634C2.76879 6.04224 2.36391 5.92439 2.00979 5.72759V5.76143C2.00979 7.03412 2.91483 8.09593 4.11704 8.33727C3.896 8.39704 3.66424 8.42862 3.42515 8.42862C3.25599 8.42862 3.09077 8.41283 2.93062 8.38238C3.26501 9.42558 4.2349 10.1857 5.38467 10.206C4.2902 11.0637 2.88173 11.3616 1.50281 11.1992"
                stroke="currentColor"
                strokeWidth=".9"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M13.1965 3.56879C13.7282 3.50507 14.234 3.3641 14.7049 3.15546L14.7038 3.15715C14.3525 3.68326 13.907 4.14508 13.3939 4.51612L12.6833 2.75228C13.2816 2.63499 13.8438 2.4162 14.3513 2.11508C14.1551 2.72859 13.7389 3.24399 13.1965 3.56879Z"
                fill="currentColor"
              ></path>
            </svg>
          </a>

          <a
            href="https://www.linkedin.com/in/bipinstha/"
            target="_blank"
            className="linkedin"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="27"
              viewBox="0 0 24 24"
            >
              <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
            </svg>
          </a>
        </div>
      </footer>
    </div>
  );
};
