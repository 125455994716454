import React from "react";
import { ThemeToggler } from "gatsby-plugin-dark-mode";
import ReactSwitch from "react-switch";

const checkedIcon = (
  <img
    alt="moon indicating dark mode"
    src="/assets/moon.png"
    className="dark-icon"
    width="17"
    height="17"
    role="presentation"
    css={{
      pointerEvents: `none`,
      margin: 4,
    }}
  />
);

const uncheckedIcon = (
  <img
    alt="sun indicating light mode"
    src="/assets/sun.png"
    className="dark-icon"
    width="17"
    height="17"
    role="presentation"
    css={{
      pointerEvents: `none`,
      margin: 4,
    }}
  />
);

export default () => (
  <ThemeToggler>
    {({ theme, toggleTheme }) => {
      if (!theme) return "";
      return (
        <ReactSwitch
          aria-label="Toggle dark mode"
          onColor="#000"
          offColor="#000"
          height={24}
          width={50}
          checkedIcon={checkedIcon}
          uncheckedIcon={uncheckedIcon}
          checked={theme === "dark"}
          onChange={() => toggleTheme(theme === "dark" ? "light" : "dark")}
        />
      );
    }}
  </ThemeToggler>
);
